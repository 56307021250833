<template>
  <div class="result">
    <van-nav-bar
      :title="title"
      left-text="返回首页"
      left-arrow
      @click-left="onClickLeft"
    />
    <template v-if="type === 'result'">
      <div v-if="!status">
        <p class="iconfont icon" style="color: #63ded2">&#xe610;</p>
        <p style="color: #63ded2">订单提交成功</p>
      </div>
      <div v-else>
        <p class="iconfont icon" style="color: #63ded2" v-show="orderStatus">&#xe610;</p>
        <p style="color: #63ded2" v-show="orderStatus">订单提交成功</p>
        <p class="iconfont icon" style="color: #f15867" @click="reload" v-show="!orderStatus">
          &#xe759;
        </p>
        <p style="color: #f15867" v-show="!orderStatus">{{ payStatus }}, 如您已支付请点击刷新按钮查询订单</p>
      </div>
      <ul class="order-info">
        <li>
          <div>订单编号：</div>
          <div>{{ orderNo }}</div>
        </li>
        <!-- <li>
          <div>下单时间：</div>
          <div>2022-04-01 12:12:12</div>
        </li> -->
      </ul>
    </template>

    <template v-if="type === 'begin'">
      <p class="iconfont icon" style="color: #f15867" @click="reload">
        &#xe759;
      </p>
      <p style="color: #f15867">{{ payStatus }}</p>
    </template>

    <template v-if="type === 'error'">
      <p class="iconfont icon" style="color: #f15867">
        <img style="width: 66px" src="~@/assets/failed.png" alt="" />
      </p>
      <p style="margin-bottom: 60px; color: #f15867">查询失败</p>
    </template>
    <van-button type="default" class="resBtn" @click="onClickLeft"
      >返回首页</van-button
    >
    <!-- <van-button type="danger" class="resBtn TopBtn" v-if="type === 'begin'" @click="again">重新支付</van-button> -->
    <!-- <van-button type="danger" class="resBtn TopBtn" v-if="type === 'result'" @click="express">查看物流</van-button> -->

    <van-overlay :show="bindShow">
      <van-loading size="24px" vertical style="margin-top: 50%"
        >数据查询中</van-loading
      >
    </van-overlay>
  </div>
</template>

<script>
// let interval;
import { getOrderDetails, getOrderInfo } from "@/api/api";
export default {
  name: "orderResult",
  data() {
    return {
      type: "result",
      title: "订单信息",
      orderNo: "",
      bindShow: false,
      success: false,
      payStatus: "订单信息查询中，点击刷新查询",
      status: false,
      orderStatus: false
    };
  },
  created() {
    this.orderNo = this.$route.params.id;
    this.status = this.$route.query.status
    console.log('==================', this.status)
    this.getOrderInfo()
    // console.log("参数", this.$store.state);
    // this.title = `id${this.$store.state.orderId}`
    // this.loadStatus();
    // this.loadOrderDetails(0);
  },
  methods: {
    reload() {
      location.reload();
    },
    getOrderInfo() {
      getOrderInfo(this.orderNo).then((res) => {
        if (res.result.statusCode === 2) {
          this.orderStatus = true;
        } else {
          this.orderStatus = false;
        }
        this.payStatus = res.result.statusDesc
      })
      // return new Promise((resolve, reject) => {
      //   getOrderDetails(this.$route.params.id)()
      //     .then((res) => {
      //       if (res.code === 200) {
      //         resolve();
      //       }
      //     })
      //     .catch((err) => {
      //       reject(err);
      //     });
      // });
    },
    async loadOrderDetails(time) {
      if (time >= 5) {
        this.type = "error";
        this.bindShow = false;
        return;
      }
      try {
        const result = await this.getOrderInfo();
        if (result.code === 200) {
          this.type = "result";
        }
      } catch (e) {
        this.loadOrderDetails(time + 1);
      }
    },
    // loadStatus() {
    //   let count = 0;
    //   let _this = this;
    //   interval = setInterval(function () {
    //     count += 1;
    //     _this._getOrderStatus();
    //     if (count === 5) {
    //       _this.bindShow = false;
    //       if (_this.type === "begin") {
    //         _this.payStatus = "未查询到支付信息，如果您已支付请点击图标刷新";
    //       }
    //       clearInterval(interval);
    //     }
    //   }, 1000);
    // },
    // 获取订单支付状态
    // _getOrderStatus() {
    //   let params = {
    //     orderNo: this.orderNo,
    //   };
    //   getOrderStatus(params).then((res) => {
    //     if (res && res.code === 200) {
    //       if (res.result) {
    //         clearInterval(interval);
    //         this.type = "result";
    //         this.$router.push({
    //           path: `/Details/${this.orderNo}`,
    //         });
    //       } else if (!res.result) {
    //         this.type = "begin";
    //       } else {
    //         this.type = "error";
    //       }
    //     }
    //   });
    // },
    onClickLeft() {
      // this.$router.replace({
      //   path: `/placeAnOrder/${this.$store.state.orderId}`
      // })
      console.log(this.$store.state.pathName);
      this.$router.push({
        // path: `/placeAnOrder/${this.$store.state.orderId}`,
        path: this.$store.state.pathName,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.result {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  text-align: center;
  .icon {
    font-size: 51px;
    margin-top: 36px;
    margin-bottom: 16px;
  }
  .TopBtn {
    margin-top: 68px;
  }
  .resBtn {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .order-info {
    padding: 20px 10% 0;
    li {
      padding: 4px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #323233;
    }
  }
}
</style>
